<template>
  <div class="container is-max-desktop">
    <job-form></job-form>
  </div>
</template>

<script>
import JobForm from "../../components/forms/JobForm.vue";

export default {
  components: {
    JobForm,
  },
};
</script>

<style>
.no-shadow {
  box-shadow: none !important;
}
</style>
